import React, { Component } from 'react';
import { NavLink, HashRouter } from 'react-router-dom';
import Axios from '../../config/Axios';
import * as Url from '../../config/Url';
import { Table } from 'reactstrap';
import { AltCheckbox } from '../../components/AltCheckbox';
import { getVouchers, putVoucher } from '../../actions/ActionVoucher';
import { Alert } from '../../components/Alert'
import Plan from './Plan';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';

class PlanIndex extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      aPlans: {},
      bLoading: false,
      aVouchers: []
    };
  }

  componentDidMount = () => {
    const { setAlert } = this.props;
    getVouchers(50)
      .then(oData => {
        this.setState({ bLoading: false, aVouchers: oData.data });
      })
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  };

  showEditPlan = (evt, oCustItem) => {
    evt.preventDefault();
    const { setAlert } = this.props;

    this.setState({
      rcmpAlert: (
        <SweetAlert customClass="modal-edit lg" title="" onConfirm={this.hideAlert} showConfirm={false}>
          <div className="head-modal">
            Editar cliente
            <span role="button" tabIndex="0" onKeyPress={this.hideAlert} onClick={this.hideAlert} className="close" />
          </div>
          <Plan
            bIsEditing
            bIsModal
            nCustId={oCustItem.cust_nid}
            fnHandleClose={this.hideAlert}
            fnHandleConfirm={(cType, cMsg) => {
              this.hideAlert();
              this.getInitData();
              setAlert(cType, cMsg);
            }}
          />
        </SweetAlert>
      )
    });
  }

  editUser = (nId) => {
    this.props.history.push(`/Plan/Update/${nId}`);
  };

  renderPlans() {
    const rcmpPlanList = Object.keys(this.state.aPlans).map((cKey) => {
      const oPlan = this.state.aPlans[cKey];

      return (
        <div key={cKey} className="plan">
          <div className="buttons">
            <button title="Editar" className="edit" onClick={() => this.editUser(oPlan.plan_nid)} />
          </div>
          <h2>
            <span>{oPlan.plan_cname}</span>
          </h2>
          <ul className="benefits">
            <li>Usuários ilimitados</li>
            <li>Até {oPlan.plan_nquota} bytes de espaço em disco</li>
            <li>Permissões personalizáveis</li>
            <li>{oPlan.plan_ntrialdays} dias de trial</li>
          </ul>
          <p className="price">{oPlan.plan_nvalue}</p>
        </div>
      );
    });

    return <div> {rcmpPlanList}</div>;
  }

  handleChange = (evt, index) => {
    const { setAlert } = this.props;
    const { aVouchers } = this.state;

    if (evt.target.type === 'checkbox') {
      aVouchers[index][evt.target.name] = evt.target.checked;
    } else if (evt.target.value !== null) {
      aVouchers[index][evt.target.name] = evt.target.value;
    }

    putVoucher(aVouchers[index]).then(() => this.setState({ bLoading: false, aVouchers }))
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  };

  render() {
    if (!this.state) {
      return 'loading ...';
    }

    const { aVouchers, rcmpAlert } = this.state;
    return (
      <>
        {rcmpAlert}
        <div>
          <div className="title-button">
            <h1>Vouchers Disponíveis</h1>

            <div className="button-stack">
              <HashRouter>
                <NavLink className="btn alternate" to="/Voucher/Create" title="Voucher">
                  Criar voucher
                </NavLink>
              </HashRouter>
            </div>
          </div>

          <Table hover responsive striped>
            <thead>
              <tr>
                <th>#</th>
                <th>Código</th>
                <th>Desconto(%)</th>
                <th>Responsável</th>
                <th>Início/Dias</th>
                <th>Descrição</th>
                <th>Ativo</th>
                <th>Data de Expiração</th>
                <th>Data de Criação</th>
              </tr>
            </thead>
            <tbody>
              {aVouchers.map((oVoucher, i) => (
                <tr key={oVoucher.vch_nid}>
                  <td>{oVoucher.vch_nid}</td>
                  <td title={oVoucher.vch_ccode}>
                    <span >
                      {oVoucher.vch_ccode}
                    </span>
                  </td>
                  <td>{`${oVoucher.vch_ndiscount}%`}</td>
                  <td>{oVoucher.user.adm_cname}</td>
                  <td>{oVoucher.vch_ndaysbegin}</td>
                  <td>
                    <span className='abbreviate-text'>
                      {oVoucher.vch_cdescription}
                    </span>
                  </td>
                  <td>
                    <AltCheckbox
                      className=""
                      name="vch_bactive"
                      id="vch_bactive"
                      onChange={(evt) => this.handleChange(evt, i)}
                      checked={oVoucher.vch_bactive}
                    />

                  </td>
                  <td>
                    {oVoucher.vch_dexpiration ?
                      moment.parseZone(oVoucher.vch_dexpiration).format('DD/MM/YYYY') : ''}
                  </td>
                  <td>
                    {moment.parseZone(oVoucher.vch_dcreated).format('DD/MM/YYYY H:mm')}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}

export default PlanIndex;

import React, { Component } from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

export class InputPercentage extends Component {
  static propTypes = {
    inputid: PropTypes.string,
    inputplaceholder: PropTypes.string,
    inputname: PropTypes.string,
    maxlength: PropTypes.number
  };

  static defaultProps = {
    inputid: '',
    inputplaceholder: '',
    inputname: '',
    maxlength: 2
  };

  constructor(oProps) {
    super(oProps);

    this.state = {
      value: oProps.value
    };
  }

  handleChange = (evt) => {
    const { onChange } = this.props;
    onChange(evt);
  };

  render() {
    const { inputid, inputplaceholder, inputname, maxlength } = this.props;
    const { value } = this.state;

    return (
      <div className="percentage">
        <Input
          maxLength={maxlength}
          className="form-control"
          id={inputid}
          placeholder={inputplaceholder}
          name={inputname}
          type="text"
          value={value}
          {...this.props}
          onChange={this.handleChange}
        />
        <span
          className="btn-hover"
        />
      </div>
    );
  }
}

export default InputPercentage;

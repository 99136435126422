import Axios from '../config/Axios';

const getVouchers = (nPerPage) => Axios.get('/voucher', {
  params: {
    per_page: nPerPage
  }
}).then(oResponse => oResponse.data)

const postVoucher = (oVoucher) => {
  const { adm_nid, vch_ccode, vch_ndiscount, vch_cdescription, vch_ndaysbegin, vch_dexpiration } = oVoucher;

  return Axios.post('/voucher', {
    adm_nid,
    vch_ccode,
    vch_ndiscount,
    vch_cdescription,
    vch_ndaysbegin,
    vch_dexpiration
  })
}

const putVoucher = (oVoucher) => {
  const { vch_nid, vch_bactive } = oVoucher;

  return Axios.put(`/voucher/${vch_nid}`, {
    vch_bactive
  })
}

export { getVouchers, postVoucher, putVoucher };
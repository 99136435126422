import React, { Component } from 'react';
import { HashRouter, Route } from 'react-router-dom';
import withAlert from '../../components/withAlert';
import Customer from '../customer/Customer';
import CustomerIndex from '../customer/CustomerIndex';
import Index from './Index';
import Module from '../module/Module';
import ModuleIndex from '../module/ModuleIndex';
import Term from '../term/Term';
import TermIndex from '../term/TermIndex';
import User from '../user/User';
import Users from '../user/UserIndex';
import PlanIndex from '../plan/PlanIndex';
import Voucher from '../voucher/Voucher';
import Norm from '../norm/Norm';
import NormIndex from '../norm/NormIndex';
import Requisite from '../requisite/Requisite';
import RequisiteIndex from '../requisite/RequisiteIndex';
import BoardModelndex from '../boardModel/BoardModelndex';
import TutorialVideoslndex from '../tutorialVideos/TutorialVideosIndex';

class RouterMain extends Component {
  render() {
    return (
      <HashRouter>
        <div>
          <Route exact path="/" component={withAlert(Index)} />
          <Route exact path="/Customer" component={withAlert(CustomerIndex)} />
          <Route exact path="/Module" component={withAlert(ModuleIndex)} />
          <Route exact path="/User" component={withAlert(Users)} />
          <Route exact path="/User/Create" component={withAlert(User)} />
          <Route exact path="/Customer/Create" component={withAlert(Customer)} />
          <Route exact path="/Module/Create" component={withAlert(Module)} />
          <Route exact path="/Module/Update/:nModuleId" component={withAlert(Module)} />
          <Route exact path="/Term" component={withAlert(TermIndex)} />
          <Route exact path="/Term/Create" component={withAlert(Term)} />
          <Route exact path="/Term/Update/:nAdctId" component={withAlert(Term)} />
          <Route exact path="/Norm" component={withAlert(NormIndex)} />
          <Route exact path="/Norm/Create" component={withAlert(Norm)} />
          <Route exact path="/Norm/Update/:nNormId" component={withAlert(Norm)} />
          <Route exact path="/Plan" component={withAlert(PlanIndex)} />
          <Route exact path="/Voucher/Create" component={withAlert(Voucher)} />
          <Route exact path="/Requisite/:nNormId" component={withAlert(RequisiteIndex)} />
          <Route exact path="/Requisite/Create" component={withAlert(Requisite)} />
          <Route exact path="/Requisite/Update/:nRequisiteId" component={withAlert(Requisite)} />
          <Route exact path="/board-models" component={withAlert(BoardModelndex)} />
          <Route exact path="/tutorial-videos" component={withAlert(TutorialVideoslndex)} />
        </div>
      </HashRouter>
    );
  }
}

export default RouterMain;

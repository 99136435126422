import axios from 'axios';
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import MaskedInput from 'react-text-mask';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import imgfLoading from '../../assets/img/preloader-black.gif';
import { Alert } from '../../components/Alert';
import Axios from '../../config/Axios';
import { validateCNPJ, validateCPF } from '../../config/Validator';
import PageClean from '../default/PageClean';

const oShow = {
  display: 'block'
};

const oHide = {
  display: 'none'
};

class Customer extends Component {

  static propTypes = {
    bIsEditing: PropTypes.bool,
    bIsModal: PropTypes.bool,
    fnHandleClose: PropTypes.func,
    fnHandleConfirm: PropTypes.func,
  };

  static defaultProps = {
    bIsEditing: false,
    bIsModal: false,
    fnHandleClose: evtHandleClose => { },
    fnHandleConfirm: evtHandleConfirm => { },
  };

  constructor(oProps) {
    super(oProps);

    this.state = {
      "oUser": [],
      "oCustomer": {
        "cust_ctype": 'PF',
        "cust_clanguage": "pt-br"
      },
      bLoading: true,
      rcmpAlertMsg: null
    };
  }


  componentDidMount() {
    const { bIsEditing, nCustId, setAlert, onClose, onUpdate } = this.props;
    if (bIsEditing) {
      Axios.get(`customer/${nCustId}`)
        .then(oResponse => {
          this.setState({
            oCustomer: oResponse.data.oCustomer,
            bLoading: false
          });
        })
        .catch(oError => {
          this.setState({
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.aMsgErrors}
              </Alert>
            )
          });
        })
    }
  }

  verifyPostalCode = (evt) => {
    const { oCustomer } = this.state;
    const cPostalCode = document.getElementById('cust_cpostalcode').value.replace('-', '');

    if(cPostalCode === '') {
      return null;
    }

    axios
      .get(`https://cep.acredite.se/cep/${cPostalCode}/json`)
      .then((oResponse) => {
        Object.keys(oResponse.data.result).map((cItem) => {
          switch (cItem) {
            case 'logradouro':
              oCustomer.cust_cstreet = oResponse.data.result[cItem];
              break;
            case 'localidade':
              oCustomer.cust_ccity = oResponse.data.result[cItem];
              break;
            case 'estado':
              oCustomer.cust_cstate = oResponse.data.result[cItem];
              break;
            case 'bairro':
              oCustomer.cust_cdistrict = oResponse.data.result[cItem];
              break;
            case 'cibgelocalidade':
              oCustomer.cust_cibgecode = oResponse.data.result[cItem];
              break;
            default:
              break;
          }
          oCustomer.cust_ccountry = 'Brasil';
          this.setState({ oCustomer });
          return oCustomer;
        });
      });
  };

  showPassword = () => {
    const elInputUserPassword = document.getElementById('user_cpassword');
    if (elInputUserPassword.type === 'password') {
      elInputUserPassword.type = 'text';
      elInputUserPassword.parentNode.children[1].classList.add('alt');
    } else {
      elInputUserPassword.type = 'password';
      elInputUserPassword.parentNode.children[1].classList.remove('alt');
    }
  };

  inputChangeHandler = (evt) => {
    let { oCustomer } = this.state;
    oCustomer = { ...oCustomer };
    if (evt.target.value != null) {
      oCustomer[evt.target.name] = evt.target.value;
      this.setState({
        oCustomer
      });
    }
  };

  inputChangeHandlerUser = (evt) => {
    let { oUser } = this.state;
    oUser = { ...oUser };
    if (evt.target.value != null) {
      oUser[evt.target.name] = evt.target.value;
      this.setState({
        oUser
      });
    }
  };

  handleSubmit = (evt) => {
    evt.preventDefault();

    const { oCustomer, oUser } = this.state;
    const { history, bIsEditing, fnHandleConfirm } = this.props;

    if (bIsEditing) {
      Axios.put(`customer/${oCustomer.cust_nid}`, {
        oCustomer
      })
        .then((oResponse) => {
          fnHandleConfirm('success', oResponse.data.success);
        })
        .catch((oError) => {
          this.setState({
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.aMsgErrors}
              </Alert>
            )
          });
        });
    } else {
      this.setState({
        rcmpAlertMsg: (
          <SweetAlert disabled showConfirm={false} title="Cadastrando cliente..." onConfirm={() => { }}>
            <img alt="Carregando" src={imgfLoading} />
          </SweetAlert>
        )
      });

      oUser['user_cname'] = oCustomer.cust_cname;
      oCustomer.oUser = oUser;

      Axios.post('customer', {
        oCustomer,
        oUser
      })
        .then(() => {
          this.setState({
            rcmpAlertMsg: (
              <SweetAlert
                success
                title="Cadastrado!"
                onConfirm={() => {
                  this.hideAlert();
                  history.push('/Customer');
                }}
              >
                Cliente cadastrado com sucesso.
              </SweetAlert>
            )
          });
        })
        .catch((oError) => {
          this.setState({
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.aMsgErrors}
              </Alert>
            )
          });
        });
    }
  };

  validateCustomerCNPJ = (evt) => {
    if (!validateCNPJ(evt.target.value)) {
      const { oCustomer } = this.state;
      oCustomer.cCnpj = '';
      this.setState({
        oCustomer,
        rcmpAlertMsg: (
          <SweetAlert
            danger
            confirmBtnText="Ok"
            confirmBtnBsStyle="danger"
            title="CNPJ inválido"
            onConfirm={this.hideAlert}
            onCancel={this.hideAlert}
          />
        )
      });
    }
  };

  validateCustomerCPF = (evt) => {
    if (!validateCPF(evt.target.value)) {
      const { oCustomer } = this.state;
      oCustomer.cust_ccpf = '';
      this.setState({
        oCustomer,
        rcmpAlertMsg: (
          <SweetAlert
            danger
            confirmBtnText="Ok"
            confirmBtnBsStyle="danger"
            title="CPF inválido"
            onConfirm={this.hideAlert}
            onCancel={this.hideAlert}
          />
        )
      });
    }
  };

  hideAlert = () => {
    this.setState({
      rcmpAlertMsg: null
    });
  };

  renderForm = () => {
    const { oCustomer, oUser  } = this.state;
    const { bIsEditing, fnHandleClose, history } = this.props;

    return (
      <>
        <form autoComplete="false" onSubmit={this.handleSubmit} className="form">
          <fieldset>
            <legend>Informações cadastrais</legend>
            <label className="split">
              Tipo de cadastro <span className="required">*</span>
              <Input
                type="select"
                name="cust_ctype"
                id="cust_ctype"
                onChange={this.inputChangeHandler}
                value={oCustomer.cust_ctype || 'PF'}
                required
              >
                <option value="PF">Pessoa Física</option>
                <option value="PJ">Pessoa Jurídica</option>
              </Input>
            </label>

            <label className="split2">
              {oCustomer.cust_ctype === 'PJ' ? 'Razão Social' : 'Nome Completo'} <span className="required">*</span>
              <Input
                type="text"
                name="cust_cname"
                id="cust_cname"
                maxLength="250"
                required
                onChange={this.inputChangeHandler}
                value={oCustomer.cust_cname || ''}
              />
            </label>

            <label style={oCustomer.cust_ctype === 'PF' ? oShow : oHide} className="split2">
              CPF <span className="required">*</span>
              <MaskedInput
                onBlur={this.validateCustomerCPF}
                // disabled={this.state.cliente.ccpf ? true : false}
                mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                onChange={this.inputChangeHandler}
                render={(cRef, oProps) => (
                  <Input
                    innerRef={cRef}
                    {...oProps}
                    value={oCustomer.cust_ccpf || ''}
                    type="text"
                    name="cust_ccpf"
                    id="cust_ccpf"
                    className="mask-cpf"
                    placeholder="CPF"
                    required={oCustomer.cust_ctype === 'PF'}
                  />
                )}
              />
            </label>

            <label style={oCustomer.cust_ctype === 'PJ' ? oShow : oHide} className="split2">
              CNPJ <span className="required">*</span>
              <MaskedInput
                onBlur={this.validateCustomerCNPJ}
                // disabled={this.state.cliente.ccnpj ? true : false}
                mask={[
                  /\d/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/
                ]}
                onChange={this.inputChangeHandler}
                render={(cRef, oProps) => (
                  <Input
                    innerRef={cRef}
                    {...oProps}
                    value={oCustomer.cust_ccnpj || ''}
                    type="text"
                    name="cust_ccnpj"
                    id="cust_ccnpj"
                    placeholder="CNPJ"
                    required={oCustomer.cust_ctype === 'PJ'}
                  />
                )}
              />
            </label>

            <label style={oCustomer.cust_ctype === 'PJ' ? oShow : oHide} className="split2">
              <span>Nome fantasia</span>
              <Input
                onChange={this.inputChangeHandler}
                value={oCustomer.cust_cfantasyname || ''}
                type="text"
                name="cust_cfantasyname"
                id="cust_cfantasyname"
                placeholder="Nome fantasia"
              />
            </label>

            <label className="split2">
              Telefone
              <MaskedInput
                mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]}
                onChange={this.inputChangeHandler}
                render={(cRef, oProps) => (
                  <Input
                    innerRef={cRef}
                    {...oProps}
                    value={oCustomer.cust_cphone || ''}
                    type="text"
                    name="cust_cphone"
                    id="cust_cphone"
                    className="mask-phone"
                    placeholder="Telefone"
                  />
                )}
              />
            </label>

            <label className="split2">
              Celular <span className="required">*</span>
              <MaskedInput
                mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]}
                onChange={this.inputChangeHandler}
                render={(cRef, oProps) => (
                  <Input
                    innerRef={cRef}
                    {...oProps}
                    value={oCustomer.cust_ccellphone || ''}
                    type="text"
                    name="cust_ccellphone"
                    id="cust_ccellphone"
                    className="mask-phone"
                    placeholder="Celular"
                    required
                  />
                )}
              />
            </label>
          </fieldset>

          <fieldset>
            <legend>Informações comerciais</legend>

            <label className="split2">
              Voucher
              <Input
                onChange={this.inputChangeHandler}
                value={oCustomer.cust_csellervoucher || ''}
                type="text"
                name="cust_csellervoucher"
                id="cust_csellervoucher"
                placeholder="Voucher"
              />
            </label>
          </fieldset>

          <fieldset>
            <legend>Configurações de localização</legend>

            <label className="split2">
              Idioma padrão <span className="required">*</span>
              <Input
                type="select"
                name="select"
                id="cust_clanguage"
                name="cust_clanguage"
                onChange={this.inputChangeHandler} value={oCustomer.cust_clanguage || ''}
                required
              >
                <option value="pt-br">Português</option>
                <option value="en">English</option>
              </Input>
            </label>
            <label className="split2">
              Tipo
              <Input
                type="select"
                name="cust_ctypeuser"
                id="cust_ctypeuser"
                onChange={this.inputChangeHandler}
                value={oCustomer.cust_ctypeuser || 'Cliente'}
                required>
                <option value="Cliente">Cliente</option>
                <option value="Consultoria">Consultoria</option>
                <option value="Certificadora">Certificadora</option>
              </Input>
            </label>
          </fieldset>

          <fieldset>
            <legend>Endereço</legend>

            <label className="split2 search">
              CEP
              <MaskedInput
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                onChange={this.inputChangeHandler}
                render={(cRef, oProps) => (
                  <Input
                    innerRef={cRef}
                    {...oProps}
                    value={oCustomer.cust_cpostalcode || ''}
                    type="text"
                    className="mask-cep cep-fill"
                    name="cust_cpostalcode"
                    id="cust_cpostalcode"
                    placeholder="CEP"
                  />
                )}
              />
              <span onClick={this.verifyPostalCode} title="Buscar Cep" className="btn-hover" />
            </label>

            <label className="split2">
              Endereço
              <Input
                onChange={this.inputChangeHandler}
                value={oCustomer.cust_cstreet || ''}
                type="text"
                name="cust_cstreet"
                id="cust_cstreet"
                placeholder="Endereço"
              />
            </label>

            <label className="split2">
              Número
              <Input
                onChange={this.inputChangeHandler}
                value={oCustomer.cust_cnumber || ''}
                type="text"
                name="cust_cnumber"
                id="cust_cnumber"
                placeholder="Número"
              />
            </label>

            <label className="split2">
              Complemento
              <Input
                onChange={this.inputChangeHandler}
                value={oCustomer.cust_ccomplement || ''}
                type="text"
                name="cust_ccomplement"
                id="cust_ccomplement"
                placeholder="Complemento"
              />
            </label>

            <label className="split2">
              Bairro
              <Input
                onChange={this.inputChangeHandler}
                value={oCustomer.cust_cdistrict || ''}
                type="text"
                name="cust_cdistrict"
                id="cust_cdistrict"
                placeholder="Bairro"
              />
            </label>

            <label className="split2">
              Cidade
              <Input
                onChange={this.inputChangeHandler}
                value={oCustomer.cust_ccity || ''}
                type="text"
                name="cust_ccity"
                id="cust_ccity"
                placeholder="Cidade"
              />
            </label>

            <label className="split2">
              Estado
              <Input
                onChange={this.inputChangeHandler}
                value={oCustomer.cust_cstate || ''}
                type="text"
                name="cust_cstate"
                id="cust_cstate"
                placeholder="Estado"
              />
            </label>

            <label className="split2">
              País
              <Input
                onChange={this.inputChangeHandler}
                value={oCustomer.cust_ccountry || ''}
                type="text"
                name="cust_ccountry"
                id="cust_ccountry"
                placeholder="País"
              />
            </label>
          </fieldset>

          {!bIsEditing ? (
            <fieldset>
              <legend>Usuário Admin</legend>

              <label className="split2">
                E-mail <span className="required">*</span>
                <Input
                  onChange={this.inputChangeHandlerUser}
                  value={oUser.user_cemail || ''}
                  type="email"
                  name="user_cemail"
                  id="user_cemail"
                  placeholder="E-mail"
                  autoComplete="new-email"
                  required
                />
              </label>
              <label className="split2 password">
                Senha <span className="required">*</span>
                <Input
                  onChange={this.inputChangeHandlerUser}
                  value={oUser.user_cpassword || ''}
                  type="password"
                  name="user_cpassword"
                  id="user_cpassword"
                  placeholder="Senha"
                  autoComplete="new-password"
                  required
                />
                <span onClick={this.showPassword} title="Mostrar/esconder senha" className="btn-hover" />
              </label>
            </fieldset>
          ) : ''}

          <div className="button-stack">
            <button type="button" className="btn alternate back btn btn-outline-secondary" title="Cancelar" onClick={(evtClick) => {
            if (bIsEditing) {
              fnHandleClose();
            } else {
              history.push({
                pathname: '/Customer'
              });
            }
          }}>
              Cancelar
            </button>
            {bIsEditing ?
              (
                <button type="submit" value="salvar" className="btn btn btn-secondary" title="Salvar">
                  Salvar
                </button>
              ): (
                <button type="submit" value="salvar" className="btn btn btn-secondary" title="Criar">
                  Criar
                </button>
              )
            }
          </div>
        </form>

      </>
    );
  }

  render() {
    const { rcmpAlertMsg, bLoading  } = this.state;
    const { bIsEditing, bIsModal } = this.props;

    if (bIsModal) {
      return (
        <PageClean bLoading={bLoading ? 1 : 0}>
          {rcmpAlertMsg}
          {this.renderForm()}
        </PageClean>
      );
    }

    return (
      <div className="centered">
        {rcmpAlertMsg}
        {!bIsEditing && (
          <div className="title-button">
            <h1>Cadastrar cliente</h1>
          </div>
        )}
        {this.renderForm()}
      </div>
    );
  }
}

export default Customer;

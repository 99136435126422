import React, { Component } from 'react';
import { Input, Button } from 'reactstrap';
import Axios from '../../config/Axios';
import * as Url from '../../config/Url';
import { AltCheckbox } from '../../components/AltCheckbox';

class Plan extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      "bCreate": true,
      "oPlan": {
        "bActive": false
      },
      "aTerms": {}
    };
  }

  createPlan = (evt) => {
    evt.preventDefault();

    const oData = this.state.oPlan;
    return Axios.post(Url.default.oBase.cUrl + Url.default.oPlan.cUrl, oData)
      .then((oResponse) => {
        this.props.history.push({
          pathname: '/Plan',
          state: { "aAlertMessages": { "cType": 'success', "cMsg": 'Plano criado com sucesso.' } }
        });
      })
      .catch((oResponse) => {
        console.log(oResponse);
      });
  };

  updatePlan = (evt, nId) => {
    evt.preventDefault();

    const oData = this.state.oPlan;

    return Axios.put(`${Url.default.oBase.cUrl + Url.default.oPlan.cUrl}/${nId}`, oData)
      .then(() => {
        this.props.history.push({
          pathname: '/Plan',
          state: { "aAlertMessages": { "cType": 'success', "cMsg": 'Plano alterado com sucesso.' } }
        });
      })
      .catch((oResponse) => {
        console.log(oResponse);
      });
  };

  inputChangeHandler = (evt) => {
    const oPlan = { ...this.state.oPlan };

    if (oPlan.nValue === 0) {
      oPlan.nTrialDays = 0;
    }

    if (evt.target.type === 'checkbox') {
      oPlan[evt.target.name] = evt.target.checked;
      this.setState({
        oPlan
      });
    } else if (evt.target.value !== null) {
      oPlan[evt.target.name] = evt.target.value;
      this.setState({
        oPlan
      });
    }
  };

  componentDidMount = () => {
    const nId = this.props.match.params.id;

    if (nId) {
      Axios.get(`${Url.default.oBase.cUrl + Url.default.oPlan.cUrl}/${nId}`)
        .then((oRes) => {
          this.setState({
            "oPlan": oRes.data,
            "bCreate": false
          });
        })
        .catch((oErr) => {
          console.error('err', oErr);
        });
    }

    Axios.get(Url.default.oBase.cUrl + Url.default.oTerm.cUrl)
      .then((oRes) => {
        this.setState({
          "oTerm": oRes.data
        });
      })
      .catch((oErr) => {
        console.error('err', oErr);
      });
  };

  renderTerms = () => {
    const { aTerms, oPlan } = this.state;
    const rcmpTermList = Object.keys(aTerms).map((cKey) => {
      const oTerm = aTerms[cKey];

      return (
        <option key={cKey} value={oTerm.adct_nid}>
          {oTerm.adct_ctitle}
        </option>
      );
    });

    return (
      <Input
        type="select"
        name="adct_nid"
        id="adct_nid"
        onChange={(evt) => this.inputChangeHandler.call(this, evt)}
        value={oPlan.adct_nid || ''}
      >
        <option>Selecione um termo</option>
        {rcmpTermList}
      </Input>
    );
  };

  render() {
    return (
      <main id="container" className="centered">
        <div className="clearfix" />
        <h1> Criar Plano</h1>
        <div className="plans">
          <form className="form">
            <label className="split2">
              Nome Completo
              <input
                maxLength="250"
                name="plan_cname"
                type="text"
                value={this.state.oPlan.plan_cname || ''}
                onChange={(evt) => this.inputChangeHandler.call(this, evt)}
              />
            </label>
            <label className="split2">
              Espaço em disco (MB)
              <input
                maxLength="11"
                name="plan_nquota"
                type="text"
                value={this.state.oPlan.plan_nquota || ''}
                onChange={(evt) => this.inputChangeHandler.call(this, evt)}
              />
            </label>

            <label className="split2">
              Valor do plano
              <input
                maxLength="20"
                className="mascara-preco"
                name="plan_nvalue"
                type="text"
                value={this.state.oPlan.plan_nvalue || ''}
                onChange={(evt) => this.inputChangeHandler.call(this, evt)}
              />
            </label>

            {Number(this.state.oPlan.plan_nvalue) === 0 && (
              <label className="split2">
                Dias de trial
                <input
                  maxLength="11"
                  name="plan_ntrialdays"
                  type="text"
                  value={this.state.oPlan.plan_ntrialdays || ''}
                  onChange={(evt) => this.inputChangeHandler.call(this, evt)}
                />
              </label>
            )}

            <label className="split1">
              Limite de documentos
              <input
                maxLength="20"
                name="plan_ndocumentlimit"
                type="number"
                value={this.state.oPlan.plan_ndocumentlimit || ''}
                onChange={(evt) => this.inputChangeHandler.call(this, evt)}
              />
            </label>

            <label className="split1">
              Termo de adesão
              {this.renderTerms()}
            </label>

            <div className="clearfix" />

            <AltCheckbox
              name="plan_bisactive"
              id="plan_bisactive"
              label="Plano está ativo"
              onChange={(evt) => this.inputChangeHandler(evt)}
              checked={!!this.state.oPlan.plan_bisactive}
            />

            <AltCheckbox
              name="bUpdateSignature"
              id="bUpdateSignature"
              label="Atualizar assinaturas já existentes?"
              onChange={e => this.inputChangeHandler(e)}
              checked={!!this.state.oPlan.bUpdateSignature}
            />

            <div className="clearfix" />
            {this.state.bCreate === true && (
              <Button type="submit" className="btn" onClick={(evt) => this.createPlan(evt)}>
                Criar
              </Button>
            )}
            {this.state.bCreate === false && (
              <Button type="submit" className="btn" onClick={(evt) => this.updatePlan(evt, this.state.oPlan.plan_nid)}>
                Salvar
              </Button>
            )}
          </form>
        </div>
        <ul id="bubble-alerts" />
      </main>
    );
  }
}

export default Plan;
